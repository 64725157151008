<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="9">
                <v-card flat class="mr-3" :loading="loading">
                    <v-card-title>{{ pageTitle }}</v-card-title>
                    <v-card-subtitle>{{ pageSubtitle }}</v-card-subtitle>
                    <v-card-text v-if="product != null">
                        <v-form ref="form">
                            <v-row>
                                <v-col cols="6">
                                    <div class="subheading grey--text">
                                        General Information
                                    </div>

                                    <v-row no-gutters>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="product.style" label="Style" :rules="[ rules.required ]"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select v-model="product.categoryID" :items="categories" label="Category" item-value="id" item-text="name" :rules="[ rules.required ]"></v-select>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="product.description" label="Description"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select v-model="product.inventoryType" :items="['0','1','2','3']" label="Inventory type" :rules="[rules.required]" :disabled="isEdit"></v-select>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch v-model="product.active" label="Active"></v-switch>
                                        </v-col>

                                        <v-col cols="12">
                                            <div class="subheading grey--text my-3">
                                                Image
                                            </div>

                                            <v-img :src="product.imgSrc" width="300" v-if="product.imgSrc != null && product.imgSrc != ''" class="white--text align-start pa-2">
                                                <v-btn icon class="red--text" @click="product.imgSrc = null">
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-btn>
                                            </v-img>

                                            <image-picker v-model="imgs" :multiple="false" :max="1" :clearable="false" ref="imagePicker" v-else></image-picker>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col cols="6">
                                    <v-row no-gutters>
                                        <v-col cols="9">
                                            <div class="subheading grey--text">
                                                Price Information
                                            </div>

                                            <div class="caption">Configure product price</div>
                                        </v-col>
                                        <v-col cols="3" class="text-right">
                                            <v-btn depressed small @click="addNewPriceOption">
                                                <v-icon left>mdi-plus</v-icon>
                                                New
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                    <v-simple-table class="mt-3" fixed-header height="275px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">From</th>
                                                    <th class="text-left">To</th>
                                                    <th class="text-right">Price</th>
                                                    <th class="text-right">Active</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="p in product.prices" :key="p.id">
                                                    <td>
                                                        <v-menu :close-on-content-click="false" 
                                                            transition="scale-transition" offset-y  min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field v-model="p.fromDate" placeholder="From date" v-bind="attrs" 
                                                                    v-on="on" hide-details dense solo flat></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="p.fromDate" no-title scrollable></v-date-picker>
                                                        </v-menu>
                                                    </td>
                                                    <td>
                                                        <v-menu :close-on-content-click="false" 
                                                            transition="scale-transition" offset-y  min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field v-model="p.toDate" placeholder="To date" v-bind="attrs" 
                                                                    v-on="on" hide-details dense solo flat></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="p.toDate" no-title scrollable></v-date-picker>
                                                        </v-menu>
                                                    </td>
                                                    <td class="text-right">
                                                        <v-text-field v-model="p.price" placeholder="Price" hide-details dense 
                                                            solo flat prefix="$" class="text-money" @keydown="numbersOnly($event)"></v-text-field>
                                                    </td>
                                                    <td class="text-right">
                                                        <v-simple-checkbox v-model="p.active" dense color="primary" :ripple="false"></v-simple-checkbox>
                                                    </td>
                                                    <td class="text-right">
                                                        <v-btn icon color="red" @click="openDeleteWarning( p )"><v-icon>mdi-delete-outline</v-icon></v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-form>

                        <div class="text-right mt-7">
                            <v-btn depressed color="grey darken-4" class="white--text" @click="save" :loading="saving">Save</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card flat>
                    <v-card-title class="headline font-weight-light grey--text text--darken-4">History</v-card-title>
                    <v-card-text class="px-0">
                        <history obj-type="product" :id="productId"  :showForm="false" ref="history" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="removePriceDialog" width="500">
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    <p>Are you sure you want to remove this price option?</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="removePriceDialog = false" style="text-transform:none">Cancel</v-btn>
                    <v-btn depressed color="error" class="white--text" @click="removePrice" style="text-transform:none">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ImagePicker from '@/components/ImagePicker.vue';
import { API } from '@/inc/api';
import { _st } from '@/softech';
import History from '@/components/History.vue';

export default {
    props: {
        productId: { type: [ Number, String ], default: null }
    },
    data: () => ({
        product             : null,
        imgs                : [],
        loading             : false,
        saving              : false,

        removePriceDialog   : false,
        priceToBeRemoved    : null,

        categories          : [],

        rules               : {
            required        : v => !!v || 'required',
        },
    }),
    computed: {
        pageTitle() {
            if( _st.isNUE( this.productId ) || this.productId == 'new' ) {
                return 'Create new product';
            }
            
            return 'Edit product';
        },
        pageSubtitle() {
            if( _st.isNUE( this.productId ) ) {
                return 'Create a new product to be used within the LFA Rental App';
            }
            else {
                return 'Edit all the details of the product';
            }
        },
        isEdit() {
            return !_st.isNUE( this.productId ) && this.productId !== 'new';
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            saveProduct: 'products/saveProduct'
        }),
        async init() {
            try {
                this.cleanProductObject();
                this.loadObjects();

                if( _st.isNUE( this.productId ) || this.productId == 'new' ) {
                    return;
                }

                let api = new API();

                this.loading = true;
                let res = await api.get(`/admin/product/${this.productId}`);
                this.loading = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return false;
                }

                if( _st.isNUE( res.data.data.id ) )
                    this.$router.push('/404');

                this.product = res.data.data;
                this.product.inventoryType = this.product.inventoryType.toString();

                this.$refs.history.getHistory();
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        async loadObjects() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get('/admin/product/categories');
                this.loading = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return false;
                }

                this.categories = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        cleanProductObject() {
            this.product = {
                id              : 0,
                style           : '',
                categoryID      : '',
                category        : '',
                description     : '',
                prices          : [],
                price           : 0,
                salePrice       : 0,
                active          : false,
                inventoryType   : null,
                imgSrc          : ''
            }
        },
        addNewPriceOption() {
            this.product.prices.push({
                id          : 0,
                productId   : this.productId,
                price       : null,
                salePrice   : 0,
                fromDate    : null,
                toDate      : null,
                active      : true,
            });
        },
        openDeleteWarning( price ) {
            this.priceToBeRemoved = price;
            this.removePriceDialog = true;
        },
        removePrice() {
            this.product.prices.splice( this.product.prices.indexOf( this.priceToBeRemoved ), 1 );
            this.removePriceDialog = false;
        },
        toMoney( val ) {
            return _st.toMoney( val );
        },
        async save() {
            if( !this.$refs.form.validate() )
                return;

            try {
                this.saving = true;
                let res = await this.saveProduct({ product: this.product, newImg: _st.isEmpty( this.imgs ) ? '' : this.imgs[0].url });
                this.saving = false;

                if( res.status === false ) {
                    this.mxShowMessage( res.message, res.error ? 'error' : 'warning' );
                    return;
                }

                if( this.isEdit ) {
                    this.product = res.data;
                    this.product.inventoryType = this.product.inventoryType.toString();
                }
                else {
                    this.$router.push(`/products/${res.data.id}`);
                }

                this.mxShowMessage( 'Product saved', 'success' );
            }
            catch(error) {
                this.saving = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        }
    },
    components: {
        ImagePicker, History
    }
}
</script>

<style scoped>
.text-money >>> input {
    text-align: right;
}
.hidden {
    visibility: hidden;
}
</style>