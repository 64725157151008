<template>
    <div>
        <v-alert dense type="error" text v-if="errorMessage.length > 0">{{ errorMessage }}</v-alert>
        <v-row no-gutters>
            <template v-for="(img, ix) in images">
                <!-- <v-col sm="6" md="4" lg="3" class="mx-0" :key="ix"> -->
                    <v-img :src="getSrc(img)" max-width="110px" min-height="110px" position="top center" class="rounded" @click="setPrimary(ix)" :key="ix">
                        <div class="fill-height top-gradient">
                            <v-row no-gutters>
                                <v-col cols="9" class="pt-1" v-if="img.name != undefined || img.size != undefined">
                                    <div class="imgTitle white--text ma-0 pl-2" v-if="img.name != undefined">{{ img.name.length > 12 ? img.name.substring(0, 12) + '...' : img.name }}</div>
                                    <div class="imgSize grey--text text--lighten-2 ma-0 pl-2" v-if="img.size != undefined">{{ img.size }}</div>
                                </v-col>
                                <v-col :offset="img.name == undefined && img.size == undefined ? 9 : 0" cols="3" class="pt-1">
                                    <v-btn fab x-small depressed color="grey darken-1" class="removeBtn" @click="removeImg(ix)"><v-icon color="white">mdi-close</v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <div style="bottom:0;position:absolute;width:100%;opacity:0.9" class="blue white--text text-center" v-if="img.isMain">PRIMARY</div>
                        </div>
                    </v-img>
                <!-- </v-col> -->
            </template>
            <v-col sm="6" md="4" lg="3" class="mx-0">
                <div class="rounded grey lighten-4" style="min-height: 110px; display:flex;justify-content:center;cursor:pointer;" @click="showSelectDialog" v-if="images.length < max">
                    <v-icon size="65">mdi-plus-circle-outline</v-icon>
                </div>
            </v-col>
            <v-btn block depressed color="error" v-if="clearable" v-show="images.length > 0" @click="removeDialog = true">Remove all</v-btn>
        </v-row>
        <input type="file" :accept="accept" :multiple="multiple" ref="picker" @change="updateImages" class="hidden" />

        <v-dialog v-model="removeDialog" width="300">
            <v-card color="white">
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    Are you sure you want to remove all selected images?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="removeDialog = false" text>Cancel</v-btn>
                    <v-btn color="error" text @click="clearImages">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { _st } from '@/softech';

export default {
    props: {
        // allow selecting more than one image at a time
        multiple: {
            type: Boolean,
            default: true
        },
        // type of images allowed
        accept: {
            type: String,
            default: 'image/png, image/jpeg'
        },
        // max images allowed
        max: {
            type: Number,
            default: 5
        },
        // determine if the images can be reordered
        ordered: {
            type: Boolean,
            default: false,
        },
        // this propery determine if the user can select the main image of a gallery
        primaryImage: { 
            type: Boolean,
            default: false
        },
        // show button to clear all images
        clearable: {
            type: Boolean,
            default: true
        },
        // object to be shared of selected images
        value: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data: () => ({
        images: [],
        errorMessage: '',
        removeDialog: false,
    }),
    computed: {
        amenity() {
            return {
                id: this.local.id,
                name: this.local.name,
                previewDescription: this.local.previewDescription,
                description: this.local.description,
                active: this.local.active,
                dateAdded: this.local.dateAdded,
                imageSrc: this.local.imageSrc,
            }
        }
    },
    watch: {
        value(v) {
            this.images = v;
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.images = this.value;
        },
        updateImages() {
            this.errorMessage = '';

            if( this.images.length + this.$refs.picker.files.length > this.max ) {
                this.errorMessage = 'Only ' + this.max + ' image(s) allowed';
                return;
            }

            for( let img of this.$refs.picker.files ) {
                this.encodeImageFileAsURL(img);
            }
        },
        encodeImageFileAsURL(element) {
            var img = element;
            var reader = new FileReader();
            reader.onloadend = () => {
                // window.console.log('RESULT', reader.result);
                // this.communityMessagePicsURI.push(reader.result);
                this.images.push({
                    // name: img.name,
                    // size: ( img.size / 1024 / 1024 ) < 1 ? Math.round( img.size / 1024 ) + ' KB' : ( img.size / 1024 / 1024 ).toFixed(2) + ' MB',
                    url: reader.result,
                    primary: false,
                    order: 0,
                });

                this.$emit('input', this.images);
            }
            reader.readAsDataURL(img);
        },
        removeImg(index) {
            this.errorMessage = '';
            this.images.splice(index, 1);
            this.$emit('input', this.images);
        },
        clearImages() {
            this.images = [];
            this.$emit('input', this.images);
            this.removeDialog = false;
        },
        setPrimary(index) {
            if( !this.primaryImage )
                return;

            this.images.forEach(img => {
                img.isMain = false;
            });

            this.images[index].isMain = true;
            this.$emit('input', this.images);
        },
        showSelectDialog() {
            this.$refs.picker.click();
        },
        getSrc(img) {
            if( _st.isObject( img.url ) )
                return img.url.medium;

            return img.url;
        }
    },
}
</script>

<style scroped>
    .top-gradient {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, transparent 72px);
    }
    .rounded {
        border-radius: 5px;
    }
    .imgTitle {
        font-size: 12px;
        line-height: 1.1;
    }
    .imgSize {
        font-size: 11px;
        line-height: 1.1;
    }
    .removeBtn {
        height: 20px !important;
        width: 20px !important;
    }
    .hidden {
        visibility: hidden;
    }
</style>